<template>
  <div class="page page__home">
    <HeaderOne
      :imgpath="'assets/img/00_startseite/header/21_06_07_THI_0851.jpg'"
      :imgposition="'center 60%'"
    />
    <AsyncIntroBlock class="section" />
    <HomeSectionOne class="section" />
    <HomeSectionTwo class="section" />
    <HomeKontakt class="section" />
    <HomeSectionThree class="section" />
    <TheFooter class="section" />
  </div>
</template>

<script>
import HeaderOne from "@/components/common/header/HeaderOne";
// import IntroBlockHome from "@/components/common/introblock/IntroBlockHome.vue";
import HomeSectionOne from "../components/common/home/HomeSectionOne.vue";
import HomeSectionTwo from "../components/common/home/HomeSectionTwo.vue";
import HomeSectionThree from "../components/common/home/HomeSectionThree.vue";
import HomeKontakt from "../components/common/home/HomeKontakt.vue";
import TheFooter from "../components/common/footer/TheFooter.vue";
import {
  defineAsyncComponent,
  onBeforeUnmount,
  onMounted,
  onUpdated,
  ref,
} from "@vue/runtime-core";

//gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const AsyncIntroBlock = defineAsyncComponent(() =>
  import(
    "@/components/common/introblock/IntroBlockHome.vue" /*webpackChunkName :"AsyncIntroBlock" */
  )
);

import { useStore } from "vuex";

export default {
  components: {
    HeaderOne,
    AsyncIntroBlock,
    HomeSectionOne,
    HomeSectionTwo,
    HomeSectionThree,
    HomeKontakt,
    TheFooter,
  },

  setup() {
    const store = useStore();
    // MENU STYLINGS:
    const introBlockHome = ref(null);

    const dataMotionArray = ref([]);
    const scrollTriggerArray = ref([]);
    onMounted(() => {
      //POLITECHECK_ENDING

      dataMotionArray.value = document.querySelectorAll("[data-motion]");
      dataMotionArray.value.forEach((element) => {
        let scrollT = null;

        let el = element;

        let tl = gsap.timeline({
          // yes, we can add it to an entire timeline!
        });

        tl.set(el, { force3D: true });

        tl.set(el, { force3D: true });
        tl.from(el, {
          y: "30%",
          rotation: 0.02,
          duration: 1,
          ease: "power1.out",
          opacity: 0,
        });

        // ScrollTrigger.matchMedia({
        //   // large
        //   "(min-width: 960px)": function() {
        //     tl.set(el, { force3D: true });
        //     tl.from(el, {
        //       y: "30%",
        //       rotation: 0.02,
        //       duration: 1,
        //       ease: "none",
        //       opacity: 0,
        //     });
        //   },
        //   // small
        //   "(max-width: 959)": function() {
        //     tl.set(el, { force3D: true });
        //     tl.from(el, {
        //       // y: "30%",
        //       rotation: 0.02,
        //       duration: 1,
        //       ease: "none",
        //       opacity: 0,
        //     });
        //   },

        //   // all
        //   all: function() {
        //     // ScrollTriggers created here aren't associated with a particular media query,
        //     // so they persist.
        //   },
        // });

        scrollT = ScrollTrigger.create({
          // scroller: "#main__content",
          animation: tl,
          trigger: el,
          scrub: 0.5,
          start: "top bottom",
          end: "top 80%",
          // markers: { startColor: "green", endColor: "red", fontSize: "12px" },

          // onToggle: (self) =>
          // onUpdate: (self) => {
          //   // console.log(
          //   //   "progress:",
          //   //   self.progress.toFixed(3),
          //   //   "direction:",
          //   //   self.direction,
          //   //   "velocity",
          //   //   self.getVelocity()
          //   // );
          // },
        });
        ScrollTrigger.refresh();
        scrollTriggerArray.value.push(scrollT);
      });
      ScrollTrigger.update();
      ScrollTrigger.refresh();
      ScrollTrigger.update();
    });

    onBeforeUnmount(() => {
      ScrollTrigger.getAll().forEach((st) => st.kill());
    });

    onUpdated(() => {
      ScrollTrigger.update();
      ScrollTrigger.refresh();
      ScrollTrigger.update();
    });

    return { dataMotionArray, introBlockHome, store };
  },
};
</script>
<style
  lang="
    scss"
></style>
