<template>
  <div class="gallerieone">
    <div class="gallerieone__wrapper">
      <div class="gallerieone__content">
        <div class="gallerieone__leftblock">
          <div
            class="gallerieone__item gallerieone__item--one gallerieone__item--big"
            data-motion="'hallo'"
          >
            <GalleryPic
              :imgpath="'assets/img/00_startseite/21_06_07_THI_1113.jpg'"
              :imgposition="'center 50%'"
            />
          </div>
          <div
            class="gallerieone__item gallerieone__item--hidel gallerieone__item--three gallerieone__item--small "
            data-motion="'hallo'"
          >
            <GalleryPic
              :imgpath="'assets/img/00_startseite/21_06_07_THI_0639.jpg'"
              :imgposition="'center 68%'"
            />
          </div>
          <div
            class="gallerieone__item gallerieone__item--two "
            data-motion="'hallo'"
          >
            <HomeGalleryInfo />
          </div>
        </div>
        <div class="gallerieone__rightblock">
          <div
            class="gallerieone__item gallerieone__item--three gallerieone__item--small"
            data-motion="'hallo'"
          >
            <GalleryPic
              :imgpath="'assets/img/00_startseite/21_06_07_THI_0639.jpg'"
              :imgposition="'center 70%'"
            />
          </div>
          <div
            class="gallerieone__item gallerieone__item--four gallerieone__item--big"
            data-motion="'hallo'"
          >
            <GalleryPic
              :imgpath="'assets/img/00_startseite/21_06_07_THI_0301.jpg'"
              :imgposition="'center 50%'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeGalleryInfo from "@/components/common/home/HomeGalleryInfo.vue";
import GalleryPic from "@/components/common/gallery/GalleryPic.vue";
import getPathFunction from "@/utilities/composition/getPathFunction";
export default {
  components: { GalleryPic, HomeGalleryInfo },
  setup() {
    const { setBackGroundPath } = getPathFunction();
    return { setBackGroundPath };
  },
};
</script>

<style lang="scss"></style>
